import React from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import { formatCurrencyString } from 'use-shopping-cart/src/util';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const ProductCard = ({
  locale, product, posts, context,
}) => {
  const link = locale === 'pl' ? `/${locale}` : '';
  const { addItem } = useShoppingCart();
  const productData = posts.filter((post) => post.node.frontmatter.sku === product.sku && product.sku);
  const handleSomething = () => {
    context.addCartAnimationClass();
    setTimeout(() => (context.removeCartAnimationClass()), 2000); // same as fadein animation
  };
  return (
    <article key={product.sku} className="product-card">
      <figure>
        <img src={product.image} alt={product.name} className="card-img" />
      </figure>
      <div className="card-content-container">
        <div className="card-header">
          <h4>{product.name}</h4>
          <h4>
            {formatCurrencyString({
              value: product.price,
              currency: product.currency,
            })}
          </h4>
        </div>
        <p>{product.description}</p>
        <div className="card-action-buttons">
          {productData.length > 0
          && (
          <Link to={`${link}/shop/${productData[0].node.frontmatter.sku}`} aria-label="read more">
            <button
              aria-label="read more"
              type="button"
              className="btn btn-outlined"
            >
              Explore
            </button>
          </Link>
          )}
          <button
            aria-label="add to cart"
            type="button"
            className="btn btn-primary"
            onClick={() => { addItem(product); handleSomething(); }}
          >
            Add To Cart
          </button>
        </div>
      </div>
    </article>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};
export default ProductCard;
