import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import Products from '../components/Products/Products';
import SEO from '../util/SEO/SEO';

const test = ({ ...props }) => {
  const intl = useIntl();

  const { edges: posts } = props.data.productPosts;
  const { originalPath } = props.pathContext.intl;

  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'shop.SEOtitle' })}
        description={intl.formatMessage({ id: 'shop.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <Products posts={posts} />
    </Layout>
  );
};

test.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default test;

export const pageQuery = graphql`
  query ProductsContent($locale: String) {
    productPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageKey: { eq: "page_productpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            path
            title
            sku
            locale
          }
        }
      }
    }
  }
`;
