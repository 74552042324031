import React from 'react';
import products from '../../../functions/create-checkout/data/products.json';
import ProductCard from '../ProductCard/ProductCard';
import { SiteContext } from '../../context/SiteContextProvider';

const Products = ({ posts }) => (
  <SiteContext.Consumer>
    {(context) => (
      <section className="products-container">
        {products.map((product) => (
          <ProductCard
            key={product.sku}
            product={product}
            posts={posts}
            context={context}
          />
        ))}
      </section>
    )}
  </SiteContext.Consumer>
);
export default Products;
